import React, {useEffect, useState} from "react";
import Chart from "react-apexcharts";
import "./PieChart.css";

const PieChart = ({data,darkMode,loaded}) => {

  const [options, SetOptions] = useState({});

  useEffect(() => {
    const config = {
      labels: data.map((tokenomic) => {
        return tokenomic.title
      }),
      colors: ["#DB9A2480", "#B69A4980", "#929B6D80", "#6D9C9280", "#499DB680", "#249DDB80"],
      stroke: {colors: ["#DB9A24", "#B69A49", "#929B6D", "#6D9C92", "#499DB6", "#249DDB"]},
      chart: {
        type: 'donut',
        background: 'transparent',
        foreColor: !darkMode ? "#fff" : "#000",
      },
      dataLabels: {
        style: {
          // fontSize:16,
          colors: !darkMode ? ["#fff"] : ["#000"], // Set the text color for each label
        },
      },
      legend: {
        fontSize: 16,
        position: 'bottom',
        colors: !darkMode ? '#fff' : '#000', // Set the text color for each label
      },
      plotOptions: {
        pie: {
          donut: {
            size: '40%', // Adjust the size to control the donut radius
            labels: {
              show: false,
              total: {
                show: false,
                label: 'Total',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
    };
    SetOptions(config);
  }, [data, darkMode]);

  return (
    <div id="pieChart" className="custom-pie-chart">
      {options.legend ?
          (
            <Chart
              options={options}
              series={data.map((tokenomic) => {
                return parseInt(tokenomic.percentage)
              })}

              type="donut"
              width="100%"
            />
          )
          : <>test</>}
    </div>
  );

};

export default PieChart;
