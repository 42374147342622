import React from 'react'
import {Link} from "react-router-dom";
import formatAddress from "../../helpers/utils";
import moment from "moment";

export default function TableData({item, darkMode}) {
  return (
    <tr className='border-b-slate-700 border-b text-center text-[#E0E6E9]'>
    <td className={`${darkMode?'text-[#222]':''} py-5 px-1`}>{moment(item.date).format('MM/DD/YY')}</td>
    <td className={`${darkMode?'text-[#222]':''} py-5 px-1`}>{formatAddress(item.hash)}</td>
    <td className="py-5">
      <Link to={item.link} target="_blank">
        <button
            className={`w-fit text-xl font-medium leading-8 px-6 py-3 rounded-2xl tracking-wider ${darkMode ? 'text-[#000]' : ''}`}>details
        </button>
      </Link>
      {/*<textarea rows={1} placeholder='detail' type="text" className={`${darkMode?'bg-[#00000015] border-[#00000025] text-[#222]':'bg-[#ffffff10] border-[#ffffff20]'} border py-[6px] mx-2 px-[10px] rounded-lg sm:w-[80%] `} />*/}
    </td>
    <td className="py-5 px-1">
      <div className={`py-1 px-3 rounded-full w-fit mx-auto ${item.description==="new"?darkMode?'bg-[#9290FE55] text-[#706ee9]':'bg-[#9290FE25] text-[#9290FE]' :!darkMode?'bg-[#6BE2BE25] text-[#50D1AA]':'bg-[#6BE2BE65] text-[#35bc94]'}`}>
        {item.description}
      </div>
      </td>
    </tr>
  )
}
