import React, {useEffect, useState} from "react";
import kyc from "../../assets/images/KYC_Certificate_Aviator.png";
import {ContentService} from "../../services/content.service";

export default function AboutAviator() {
    const [content, setContent] = useState({title: '', key: '', body: ''});
    const [team, setTeam] = useState([{img_url: '', name: '', function: ''}]);
    const getContent = async () => {
        const _content = await ContentService.getContent('about');
        setContent(_content);
    }
    const getTeam = async () => {
        const _team = await ContentService.getTeam();
        setTeam(_team);
    }

    useEffect(() => {
        getContent().then(r => {});
        getTeam().then(r => {});
    },[]);

    return (
        <div className="h-full w-full flex flex-col sm:flex-row items-center justify-center 2xl:pr-20">
            <div className="flex-1 basis-2/3 flex flex-col gap-4">
                <div className="flex gap-4 items-center">
                    <div className="h-[30px] 2xl:h-[40px] w-[3px] bg-mainB rounded-sm"></div>
                    <p className="uppercase text-xl 2xl:text-3xl">About</p>
                </div>
                <h1 className="uppercase text-5xl sm:text-7xl 2xl:text-8xl font-bold leading-none -tracking-wide">
                    AVIATOR
                </h1>
                <div className="max-w-xl 2xl:max-w-4xl text-base 2xl:text-lg textbody 2xl:pb-6" >
                    <div dangerouslySetInnerHTML={{__html: content.body}}></div>
                </div>
                <img className="w-full block sm:hidden" src={kyc} alt="" />
                <div className="flex flex-col w-fit pb-3 sm:pb-0">
                    <p className="uppercase text-base 2xl:text-xl">Team</p>
                    <div className="w-full h-[3px] bg-mainY rounded-sm"></div>
                </div>
                <div className="flex gap-2 flex-col sm:flex-row flex flex-wrap ">
                    {team.map((item, i) => {
                        return (
                            <span key={'team_'+i} className="relative overflow-hidden rounded-2xl shadow-lg shadow-[#00000030]">
                      {item.url ?
                          <img
                              key={i}
                              className="w-full sm:w-[150px] 2xl:w-[250px] aspect-[5/3] object-cover"
                              src={'https://www.aviator.ac/'+item.url}
                              alt=""
                          />
                          : <></>}
                                <div className="opacity-100 transition-all absolute w-full h-full flex flex-col items-end p-3 top-0 bg-gradient-to-t from-[#00000090] to-[#00000010]">
                          <p className="capitalize tracking-wider text-light mb-2 font-bold" dangerouslySetInnerHTML={{__html: item.name}}></p>
                          <p className="capitalize tracking-wider text-light" dangerouslySetInnerHTML={{__html: item.function}}></p>
                      </div>
                  </span>
                        );
                    })}
                </div>



            </div>
            <div className="flex-1 basis-1/3 hidden sm:block">
                <img src={kyc} alt="" />
            </div>
        </div>
    );
}
