import React, { useState, useEffect } from "react";
import Piechart from "../../components/Charts/piechart";
import Table from "../../components/Table";
import {ContentService} from "../../services/content.service";
export default function Tokenomics({darkMode}) {
  const [content, setContent] = useState({title: '', key: '', body: ''});
  const [tokenomics, setTokenomics] = useState([{title: '', id: '', percentage: 0}]);
  const [loaded,setLoaded] = useState(false);
  const getContent = async () => {
    const _content = await ContentService.getContent('tokenomics');
    setContent(_content);
  }

  const getTokenomics = async () => {
    const _tokenomics = await ContentService.getTokenomics();
    setTokenomics(_tokenomics);
  }


  const [key, setKey] = useState(0);

  useEffect(() => {
    // Update the key whenever darkMode changes to trigger a re-render of Piechart
    setKey((prevKey) => prevKey + 1);
    getContent().then(r => {});
    getTokenomics().then(r => {});
    setLoaded(true);
  }, [darkMode]);

  return (
    <div className="h-full w-full  flex items-center flex-col-reverse sm:flex-row gap-10 sm:gap-0">
      <div className="w-full sm:w-[70%] flex flex-col gap-4">
        <div className=" flex gap-4 items-center">
          <div className="h-[30px] 2xl:h-[40px] w-[3px] bg-mainB rounded-sm"></div>
          <p className="uppercase text-xl 2xl:text-3xl">Tokenomics</p>
        </div>
        <div className=" 3xl:max-w-xl 2xl:max-w-4xl text-base 2xl:text-lg textbody 2xl:pb-6" dangerouslySetInnerHTML={{__html: content.body}}>

        </div>

        <div className="table">
          <Table darkMode={darkMode} />
        </div>
      </div>
      <div className="w-[115%] max-w-[420px] sm:w-[40%] sm:min-w-[450px] sm:max-w-[600px]">
        {loaded ?
            <Piechart key={key} data={tokenomics} darkMode={darkMode} loaded={loaded}/>
            : <></>
        }
        {/* <Donutchart/> */}
      </div>
    </div>
  );
}
