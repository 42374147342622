export const data = [
    {
        label:"About Aviator",
        url:"About",
    },
    {
        label:"Tokenomics",
        url:"Tokenomics",
    },
    {
        label:"Roadmap",
        url:"Roadmap",
    },
    {
        label:"Flightpaper",
        url:"Flightpaper",
    },
    {
        label:"SkyBridge",
        url:"Skybridge",
    },
    {
        label:"Aviator Arcade",
        url:"AviatorArcade",
    },

]