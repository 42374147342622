import axios from "axios";

export class ContentService {

    static async getContent(key) {
        try {
            const response = await axios({
                method: 'GET',
                url: '/content/pages/' + key,
                onError: null
            });
            return ContentService.fromContent(response.data.content);
        } catch (e) {
            console.log(e);
        }
        return {'body' : 'no content found for key: '+ key}
    }

    static async getTokenomics() {
        const response = await axios({
            method: 'GET',
            url: '/content/tokenomics'
        });
        if(response.data?.tokenomics) {
            return response.data.tokenomics.map((tokenomic) => {
                return ContentService.fromTokenomics(tokenomic);
            })
        }
        return [];
    }

    static async getTeam() {
        const response = await axios({
            method: 'GET',
            url: '/content/team'
        });
        if(response.data?.team) {
            return Promise.all(response.data.team.map(async (team) => {
                const _team = ContentService.fromTeam(team);
                const url = await this.getTeamImage(_team.id);
                if(url){
                    _team.url = url;
                }

                return _team;
            }));
        }
        return [];
    }

    static async getTransactions() {
        const response = await axios({
            method: 'GET',
            url: '/content/transactions'
        });
        if(response.data?.transactions) {
            return response.data.transactions.map((transaction) => {
                return ContentService.fromTransactions(transaction);
            })
        }
        return [];
    }

    static async getMediums() {
        const response = await axios({
            method: 'GET',
            url: '/content/mediums'
        });
        if(response.data?.mediums) {
            return response.data.mediums.map((medium) => {
                return ContentService.fromMedium(medium);
            })
        }
        return [];
    }

    static fromContent(content) {
        return {
            id: content._id,
            title: content.title,
            body: ContentService.decodeHtml(content.body),
            key: content.key
        }
    }

    static fromTokenomics(tokenomic) {
        return {
            id: tokenomic._id,
            title: tokenomic.title,
            percentage: tokenomic.percentage
        }
    }

    static fromTeam(team) {
        return {
            id: team._id,
            name: team.name,
            function: team.function,
            active: team.active,
        }
    }


    static fromTransactions(transaction) {
        return {
            id: transaction._id,
            description: transaction.description,
            date: transaction.date,
            hash: transaction.hash,
            link: transaction.link
        }
    }

    static fromMedium(medium) {
        return {
            id: medium._id,
            title: medium.title,
            url: ContentService.decodeHtml(medium.url),
            img_url: ContentService.decodeHtml(medium.img_url),
        }
    }



    static decodeHtml(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    static async getTeamImage(teamId) {
        const response = await axios({
            method: 'GET',
            url: '/multimedia/images/team/'+teamId
        });
        if(response.data?.images && response.data?.images.length) {
            return response.data?.images[0].url;
        }
    }
}