export const data = [
    {
        index:1,
        title:"Research and Development",
        detail:[
            "Conduct market research and technical analysis to identify current shortcomings in Web3",
            "Define the vision of the Aviator project and its community-powered governance structure",
        ]
    },
    {
        index:2,
        title:"Project Initialization",
        detail:[
            "Design project tokenomics, including token distribution, utility, and deployment",
            "Stealth launch on Uniswap V2 and liquidity lock",
            "Launch DAO beta on snapshot.org",
            "2023 roadmap release"
        ]

    },
    {
        index:3,
        title:"Flightpaper (Whitepaper)",
        detail:[
            "Detail plans for community-driven Web3 gaming platform and launchpad",
            "Introduce platform structure to indie developers to utilize our API to build with AVI",
            "Educate other Web3 developers on deploying games to the platform",
        ]

    },
    {
        index:4,
        title:"SkyBridge",
        detail:[
            "Document project scope and solutions",
            "Launch private alpha",
            "Open public testnet",
            "Secure security audits for all smart contracts",
            "Launch public bridge"
        ]

    },
    {
        index:5,
        title:"Aviator Arcade",
        detail:[
            "Launch private Aviator Arcade alpha build for testing and bugfixing",
            "Implement launch games and provide 5% bonus NFT to pioneer developers",
            "Launch public alpha and beta for community feedback",
            "Secure security audits for all smart contracts",
            "Aviator Arcade full launch"
        ]

    }
]