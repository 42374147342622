import React, {useEffect, useState} from "react";
import fox from "../../assets/images/animated_coin2.gif";

import { Link } from "react-router-dom";
import {ContentService} from "../../services/content.service";
import Filled from "../../components/Buttons/Filled";

export default function Home() {
    const [content, setContent] = useState({title: '', key: '', body: ''});
    const [daoContent, setDaoContent] = useState({title: '', key: '', body: ''});
    const getContent = async () => {
        const _content = await ContentService.getContent('home');
        setContent(_content);
        const _daoContent = await ContentService.getContent('dao');
        setDaoContent(_daoContent);
    }
    const [mediums, setMediums] = useState([{title: '', url: '', img_url: ''}]);

    const getMediums = async () => {
        const _mediums = await ContentService.getMediums();
        setMediums(_mediums);
    }

    useEffect(() => {
        getContent().then(r => {});
        getMediums().then(r => {});
    },[]);

  return (
    <div className="h-full w-full flex flex-col sm:flex-row items-center justify-center 2xl:pr-20">
      <div className="flex-1 basis-2/3 flex flex-col gap-4">
        <div className="flex gap-4 items-center">
          <div className="h-[30px] 2xl:h-[40px] w-[3px] bg-mainB rounded-sm"></div>
          <p className="uppercase text-xl 2xl:text-3xl">welcome to</p>
        </div>
        <h1 className="uppercase text-5xl sm:text-7xl 2xl:text-8xl font-bold leading-none -tracking-wide">
          AVIATOR
        </h1>
        <div className="max-w-xl 2xl:max-w-4xl text-base 2xl:text-lg textbody 2xl:pb-6" >
            <div dangerouslySetInnerHTML={{__html: content.body}}></div>
        </div>

        <img className="w-full block sm:hidden" src={fox} alt="" />

          <div className="flex gap-4 items-center">
              <div className="h-[30px] 2xl:h-[40px] w-[3px] bg-mainB rounded-sm"></div>
              <p className="uppercase text-xl 2xl:text-3xl">DAO</p>
          </div>
          <div className="max-w-xl 2xl:max-w-4xl text-base 2xl:text-lg textbody 2xl:pb-6" >
              <div dangerouslySetInnerHTML={{__html: daoContent.body}}></div>
              <div className="flex pt-5 gap-5 flex-wrap">
                  <a href="https://snapshot.org/#/aviator-dao.eth" target="_blank" rel="noreferrer">
                      <Filled label="Participate" color="yellow" />
                  </a>
              </div>
          </div>
          <div className="max-w-xl 2xl:max-w-4xl text-base 2xl:text-lg textbody 2xl:pb-6" >
              <div>Participate in our bug bountry program from Hacken!</div>
              <div className="flex pt-5 gap-5 flex-wrap">
                  <a href="https://hackenproof.com/aviator/aviator-smart-contracts" target="_blank" rel="noreferrer">
                      <Filled label="Bug Bounty" color="yellow" />
                  </a>
              </div>
          </div>
        <div className="flex flex-col w-fit pb-3 sm:pb-0">
          <p className="uppercase text-base 2xl:text-xl">latest articles</p>
          <div className="w-full h-[3px] bg-mainY rounded-sm"></div>
        </div>
        <div className="flex gap-5 flex-col sm:flex-row">
          {mediums.map((item, i) => {
            return (
              <Link to={item.url} key={'medium_'+i} className="relative overflow-hidden rounded-2xl shadow-lg shadow-[#00000030]" target="_blank">
                <img
                key={i}
                className="w-full sm:w-[150px] 2xl:w-[250px] aspect-[5/3] object-cover"
                src={item.img_url}
                alt="latest pic"
              />
              <div className="opacity-100 transition-all absolute w-full h-full
              flex items-end p-3 top-0 bg-gradient-to-t from-[#00000090] to-[#00000010]">
                <p className="capitalize tracking-wider text-light">{item.title}</p>
              </div>
              </Link>
            );
          })}
        </div>
      </div>
      <div className="flex-1 basis-1/3 hidden sm:block">
        <img src={fox} alt="" />
      </div>
    </div>
  );
}
