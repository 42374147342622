import React, {useEffect, useState} from 'react'
import bridge from "../../assets/images/bridge.png";
import {ContentService} from "../../services/content.service";

export default function Skybridge() {
    const [content, setContent] = useState({title: '', key: '', body: ''});

    const getContent = async () => {
        const _content = await ContentService.getContent('skybridge');
        setContent(_content);
    }

    useEffect(() => {
        // Update the key whenever darkMode changes to trigger a re-render of Piechart
        getContent().then(r => {});
    }, []);
  return (
    <div className="h-full w-full  flex items-center flex-col sm:flex-row 2xl:pr-20 gap-5 2xlgap-12">
      <div className="flex-1 basis-1/2 flex flex-col gap-4">
        <div className="flex gap-4 items-center">
          <div className="h-[30px] 2xl:h-[40px] w-[3px] bg-mainB rounded-sm"></div>
          <p className="uppercase text-xl 2xl:text-3xl">Bridge</p>
        </div>
        <p className=" text-base 2xl:text-xl textbody w-[90%]">
            Aviator’s custom bridge from the Ethereum Network to the Base Layer Two
        </p>
        <img className="block w-full sm:max-w-[60%] 2xl:max-w-[80%]" src={bridge} alt="" />
      </div>
      <div className="flex-1 basis-1/2 flex flex-col gap-5 ">
      <div className="flex flex-col w-fit" dangerouslySetInnerHTML={{__html: content.body}}>
      </div>
    </div>
    </div>
  );
}
