import React, {useEffect, useState} from 'react'
import TableData from "./TableData";
import {ContentService} from "../../services/content.service";

export default function Table({darkMode}) {
    const [transactions, setTransactions] = useState([{description: '', date: '', hash: '', link: ''}]);

    const getTransactions = async () => {
        const _transactions = await ContentService.getTransactions();
        setTransactions(_transactions);
    }

    useEffect(() => {
        // Update the key whenever darkMode changes to trigger a re-render of Piechart
        getTransactions().then(r => {});
    }, []);

  return (
    <div className={`${
      darkMode?'bg-[#3181B725]':'bg-[#3181B715]'
    } p-5 w-[calc(100vw-48px)] sm:w-auto rounded-2xl h-fit sm:max-h-[45vh] sm:overflow-y-scroll customscroller max-w-[900px]`}>
        
        <div className='max-w-full w-full overflow-x-scroll'>
        <table className="border-collapse w-full">
  <thead >
    <tr >
      <th className={`${darkMode?'bg-[#00000015]':'bg-[#ffffff10]'} py-3 px-1`}>Date</th>
      <th className={`${darkMode?'bg-[#00000015]':'bg-[#ffffff10]'} py-3 px-1`}>TX Hash</th>
      <th className={`${darkMode?'bg-[#00000015]':'bg-[#ffffff10]'} py-3 px-1`}>Detail</th>
      <th className={`${darkMode?'bg-[#00000015]':'bg-[#ffffff10]'} rounded-e-xl py-3 px-1`}>Description</th>
    </tr>
  </thead>
  <tbody>
    {transactions.map((item, index)=>{
        return(
          <TableData key={index} item={item} darkMode={darkMode}/>
        )
    })
    }
    
  </tbody>
</table>
        </div>
    </div>
  )
}
