export default function formatAddress(address: string) {
    const leadingChars = 4;
    const trailingChars = 4;
    if(address) {
        return address.length < leadingChars + trailingChars
            ? address
            : `${address.substring(0, leadingChars)}\u2026${address.substring(
                address.length - trailingChars
            )}`;
    } return '';
}